
import Vue, { PropType } from 'vue'
import { ResourceFilter } from '@/shared/types/ResourceTypes'

export default Vue.extend({
  name: 'ShopBookingMap',
  props: {
    slugType: {
      type: String as PropType<'organization' | 'community'>,
      required: true,
    },
    slug: {
      type: String,
      default: null,
    },
    resourceFilter: {
      type: Object as PropType<ResourceFilter>,
      default: null,
    },
  },
  data() {
    return {
      mapLoaded: false,
      mapComponent: null as any,
    }
  },
  computed: {
    mapHeight(): string {
      return this.$mq !== 'xs' ? '600px' : '500px'
    },
    resourceLocationFilter(): ResourceFilter & {
      organization_slug?: string
      excludeChildResources: boolean
      community_slug?: string
    } {
      if (this.slugType === 'organization') {
        return {
          ...this.resourceFilter,
          excludeChildResources: false,
          organization_slug: this.slug,
        }
      } else {
        return {
          ...this.resourceFilter,
          excludeChildResources: false,
          community_slug: this.slug,
        }
      }
    },
  },
  async mounted() {
    await this.initMapSelect()
  },
  methods: {
    async initMapSelect() {
      // lazy-load component
      if (!this.mapComponent) {
        const BookingMap = (
          await import('@/shared/components/maps/BookingMap.vue')
        ).default
        this.mapComponent = BookingMap
      }
    },
  },
})
